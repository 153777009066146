<template>
  <div class="svg-container m-2 pt-4 border-top" align="left">
    <div class="row m-2">
      <!-- charts -->
      <div class="col col-md-6 col-sm-12 border rounded" id="variantsChart">
        <h3>
          {{ title || "Chart" }}
          <a tabindex="0" class="text-info small" role="button" @mouseover="togglePopup('ivarchart')"
            @mouseleave="togglePopup(null)">
            <span id="ivarchart" class="material-icons text-info">
              info_outline
            </span></a>
        </h3>
        <div class="row">
          <div class="col col-6">
            <label for="currentColoring"><b>Show:</b></label>
            <select class="form-control" id="groupBy" name="groupBy" size="1" v-model="groupBy" @change="updateChart()">
              <option value="proteinAminoAcids">
                Counts of mutations by residue
              </option>
              <option value="aaMutationCount">
                Distinct mutations by residue
              </option>
            </select>
          </div>

          <div class="col" v-if="groupBy === 'proteinAminoAcids' || groupBy === 'aaMutationCount'
            ">
            <label for="currentColoring"><b>Color by:</b></label>
            <select class="form-control" id="colorBy" name="colorBy" size="1" v-model="colorBy" @change="updateChart()">
              <option value="aa">AA change</option>
              <option value="energy" :disabled="!energyAvailable">
                binding affinity change
              </option>
              <option value="blosum" :disabled="!blosumMatrix">
                BLOSUM score
              </option>
              <option value="growth" :disabled="!mutationsGrowth">
                recent growth
              </option>
            </select>
          </div>
        </div>
        <div class="row m-0">
          <div v-if="mutations.length >= 200">
            Selection is too large to be displayed. Please use positions filter
            to reduce number of positions (max 200).
          </div>
          <svg id="varChartSvg"></svg>
        </div>
      </div>
      <div class="col col-md-6 col-sm-12 border rounded">
        <div class="row m-0">
          <h3>
            Prevalence of mutations at position
            {{ hoveredColumn ? hoveredColumn.data.pdbPos : 0 }} over time (demo only)
            <a tabindex="0" class="text-info small" role="button" @mouseover="togglePopup('igrowthchart')"
              @mouseleave="togglePopup(null)">
              <span id="igrowthchart" class="material-icons text-info">
                info_outline
              </span></a>
          </h3>
          <MultiSeriesChart v-if="mutGrowthAtCurrentPosition.length" :colorScale="colorScale"
            :data="mutGrowthAtCurrentPosition" :hoveredGroup="hoveredColumn
              ? struct2protein(hoveredColumn.data.pdbPos) + '-' + hoveredSubgroupName
              : null
              " :svgWidth="svgWidth" :weeklyTotals="Object.values(mutationsGrowth[0])"></MultiSeriesChart>
        </div>
      </div>
    </div>
    <div class="row mt-4 ml-3" v-if="hoveredColumn">
      <h4>
        Overview of mutations at position
        {{ hoveredColumn.data.pdbPos }}
      </h4>
    </div>
    <div class="row m-2 border rounded" v-if="hoveredColumn">
      <div class="col col-md-6 col-sm-12">
        <table class="table table-striped table-sm">
          <tr width="40%">
            <th>Genome positions:</th>
            <td>
              {{ hoveredColumn.data.m.variant }}:{{
                hoveredColumn.data.m.variant + 2
              }}
            </td>
          </tr>
          <tr class="border-top">
            <th>Position in PDB:</th>
            <td>{{ hoveredColumn.data.pdbPos }}</td>
          </tr>

          <tr>
            <th>Total count of mutations:</th>
            <td>{{ hoveredColumn.data.m.mutationNumber }}</td>
          </tr>
          <tr>
            <th>Number of distinct mutations</th>
            <td>{{ hoveredColumn.data.m.aaMutationCount }}</td>
          </tr>
        </table>
      </div>
      <div class="col">
        <b>Individual mutations:</b>

        <div class="row">
          <div class="col-3">
            <b>Mutation</b><a tabindex="0" class="text-info small" role="button" @mouseover="togglePopup('itblsubst')"
              @mouseleave="togglePopup(null)">
              <span id="itblsubst" class="material-icons text-info">
                info_outline
              </span></a>
          </div>
          <div class="col-3">
            <b>Affinity change</b><a tabindex="0" class="text-info small" role="button"
              @mouseover="togglePopup('itblaffinity')" @mouseleave="togglePopup(null)">
              <span id="itblaffinity" class="material-icons text-info">
                info_outline
              </span></a>
          </div>
          <div class="col-3">
            <b>BLOSUM score</b><a tabindex="0" class="text-info small" role="button"
              @mouseover="togglePopup('itblblosum')" @mouseleave="togglePopup(null)">
              <span id="itblblosum" class="material-icons text-info">
                info_outline
              </span></a>
          </div>
          <div class="col">
            <b>Recent growth</b><a tabindex="0" class="text-info small" role="button"
              @mouseover="togglePopup('itblgrowth')" @mouseleave="togglePopup(null)">
              <span id="itblgrowth" class="material-icons text-info">
                info_outline
              </span></a>
          </div>
        </div>
        <div class="row small m-0 px-1 border-top" v-for="aa in codonAADesc" v-bind:key="aa[0]" :class="{
          'bg-dark': aa[0].indexOf(idToString(hoveredSubgroupName)) > 0,
          'font-weight-bold':
            aa[0].indexOf(idToString(hoveredSubgroupName)) > 0,
        }">
          <div class="col-3" v-bind:style="{
            'background-color': colorForAA(aa[0]),
            color: getTextColor(colorForAA(aa[0])),
          }">
            {{ aa[0] }}: {{ aa[1] }}
          </div>

          <div class="col-3" v-bind:style="{
            'background-color': colorForAAEnergyC(aa[0]),
            color: getTextColor(colorForAAEnergyC(aa[0])),
          }">
            {{
              mutationEnergy.prot[aa[0]]
              ? mutationEnergy.prot[aa[0]].energy
              : "N/A"
            }}
          </div>

          <div class="col-3" v-bind:style="{
                'background-color': colorForBlosum(aa[0]),
                color: getTextColor(colorForBlosum(aa[0])),
              }">
            {{ getBlosumScore(aa[0]) }}
          </div>

          <div class="col" v-bind:style="{
            'background-color': colorForGrowth(getRecentGrowthForAA(aa[0])),
            color: getTextColor(colorForGrowth(getRecentGrowthForAA(aa[0]))),
          }">
            {{ getRecentGrowthForAA(aa[0]) }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4" v-else>
      Move over chart to see detailed information. Click on column to label
      position in 3D viewer.
    </div>
    <div id="helpModal" style="z-index: 1000; position: absolute; width: 400px" class="modaql" tabindex="-1">
      <div v-if="hoveredSectionName" class="bg-light border border-info rounded p-2">
        <!-- // charts -->
        <div v-if="hoveredSectionName === 'ivarchart'">
          <h5 class="modal-title">Distribution of mutations</h5>
          <p>Chart shows distribution of mutations in the selected positions around ligand</p>
        </div>
        <div v-if="hoveredSectionName === 'igrowthchart'">
          <h5 class="modal-title">Recent growth</h5>
          <p>
            Chart shows prevalence of different mutations at selected position
            from the beginning of the pandemic (demo only)
          </p>
        </div>
        <!-- //table columns -->
        <div v-if="hoveredSectionName === 'itblsubst'">
          <h5 class="modal-title">Mutation</h5>
          <p>Mutations at the selected structure position and their total counts</p>
        </div>
        <div v-if="hoveredSectionName === 'itblaffinity'">
          <h5 class="modal-title">Affinity change</h5>
          <p>Binding affinity change calculated with PremPLI (available for missense mutations)</p>
        </div>

        <div v-if="hoveredSectionName === 'itblblosum'">
          <h5 class="modal-title">BLOSUM62 score</h5>
          <p>Substitution score from the BLOSUM62 matrix (available for missense mutations)</p>
        </div>
        <div v-if="hoveredSectionName === 'itblgrowth'">
          <h5 class="modal-title">Recent growth</h5>
          <p>
            Recent relative growth of this variant as calculated in the Variant
            tracking viewer tab
          </p>
        </div>

        <!-- <h5 class="modal-title">{{ popHelp[hoveredSectionName].title }}</h5>
        <p v-html="popHelp[hoveredSectionName].text"></p> -->
      </div>
    </div>
  </div>
</template>

<script>
// import { scaleLinear, scaleBand } from "d3-scale";

import Shared from "@/shared/Shared.js";

export default {
  name: "VariantsChart",

  components: {
    MultiSeriesChart: () => import("../components/MultiSeriesChart"),
  },
  mixins: [Shared],
  props: {
    title: String,
    mutations: Array,
    protein: String, // protein name only
    structure: Object
  },
  data: function () {
    return {
      START: Date.now(),
      yKey: "proteinAminoAcids",
      groupBy: "proteinAminoAcids",
      maxN: 10,
      svgWidth: 500, // width of the svg element
      svgHeight: 500, //height of the svg element
      width: 100, //width of the  chart, will change at mount
      height: 100, //height of the  chart, will change at mount
      svg: null, // group element inside svg which contains actual chart,
      mergedMutations: [], //
      mutationsUpdated: false,
      subgroups: [],
      hoveredSubgroupName: null,
      hoveredColumn: null,
      colorSet: null, // mix of several color scales
      colorScale: null, // currently used d3 color scale for AA changes
      yScale: null, // scales for zooming
      xScale: null,
      xAxis: null,
      yAxis: null,
      stackedData: null,
      colorBy: "aa", // aa, energy, growth
      // for binding energy display:
      energyFolder: "./drugresistance/energy/",
      energyAvailable: false, // set to TRUE if data was loaded, FALSE otherwise
      energyRange: { min: 0, max: 0 }, // energy range
      mutationEnergy: { pdb: {}, prot: {} }, // store mutation: energy  data
      energyColorScale: null, // divergent color scale based on energy min-max
      // for time charts display:
      mutationGrowthFolder: "./drugresistance/mutations/",
      // Growth table for selected protein, in the format { 'Pos:AAChange': [values]}
      mutationsGrowth: null,
      growthColors: ["#b2182b", "#FFF", "#2166ac"].reverse(),
      growthColorScale: null,
      // blosum
      blosumMatrix: null,
      blosumColorScale: null,
      // for popup
      hoveredSectionName: null,
      mutV2Regex: new RegExp("([a-zA-Z]+)(\\d+)([a-zA-Z]+)", "mi"),
      mainDataUrl: ''
    };
  },
  mounted() {
    this.mainDataUrl = this.getDataApiUrl()
      + this.dataUrlFromParams(this.$route.params)

    this.svgWidth =
      document.getElementById("variantsChart").offsetWidth * 0.95 || 500;
    this.svgHeight = 400; //this.svgWidth; // find better proportion or make fixed?

    const margin = { top: 10, right: 30, bottom: 20, left: 50 };

    // update the dimensions and margins of the chart
    this.width = this.svgWidth - margin.left - margin.right;
    this.height = this.svgHeight - margin.top - margin.bottom;

    // append the svg object to the body of the page
    let svgEl = window.d3
      .select("#varChartSvg")
      .attr("width", this.svgWidth)
      .attr("height", this.svgHeight);

    this.svg = svgEl
      .append("g")
      .attr("id", "varChartCanvas")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    this.loadEnergyData();

    this.loadGrowthData();

    this.loadBlosum();
    this.mergeMutations();

    this.updateChart();
  },
  watch: {
    dataParams(newParams) {

      this.mainDataUrl = this.getDataApiUrl()
        + this.getUrlPrefix()
        + this.dataUrlFromParams(newParams);
    },
    async protein() {
      await this.loadGrowthData();
      this.hoveredColumn = null;
    },
    structure() {
      this.loadEnergyData();
      this.hoveredColumn = null;
      this.updateChart();
    },
    async mutations(data) {
      await this.updateMutations(data);
    }
    ,
  },
  computed: {
    subgroupsInHovered() {
      //not used?
      if (!this.hoveredColumn || !this.hoveredColumn.data) return [];

      let res = [];
      this.hoveredColumn.data.keys.forEach((k) => {
        this.subgroups.includes(k) && res.push(k);
      });

      return res;
    },
    mutGrowthAtCurrentPosition() {
      if (!this.hoveredColumn || !this.hoveredColumn.data) return [];

      const pos = this.hoveredColumn.data.pdbPos;
      const protPos = this.struct2protein(pos);
      const regex = new RegExp("[a-zA-Z]+" + protPos + "[a-zA-Z]+", "gmi");

      let res = this.mutationsGrowth.filter((d) => {
        return (d.Mutations.match(regex) ||
          d.Mutations.startsWith("" + protPos + ":")) && d.Total > 4;
      });
      res.columns = this.mutationsGrowth.columns;
      return res;
    },
    codonAADesc() {
      let r = this.hoveredColumn.data.m.codonAaChanges;
      return r.sort((a, b) => b[1] - a[1])
    }
  },
  methods: {
    log: function (msg) {
      const t = Date.now() - this.START;
      // eslint-disable-next-line no-console
      console.log(`VarChart@ ${t}ms: ${msg}`);
    },
    updateMutations: async function (data) {
      if (!this.mutationsGrowth.length)
        await this.loadGrowthData();
      // this.log("Got new mutations");
      this.hoveredColumn = null;

      if (data.length >= 200) return;

      this.mergedMutations = this.mergeMutations();

      // all selected positions
      const positions = this.mergedMutations.map((m) => m.structPos);
      let min = 0;
      let max = 0;
      let topPos = 0; // position with max number of aa changes
      let topCount = 0;
      const self = this;
      this.mergedMutations.forEach((m) => {
        if (m.mutationNumber > topCount) {
          topCount = m.mutationNumber;
          topPos = m.structPos;
        }
      });

      positions.forEach((pos) => {
        const protPos = self.struct2protein(pos);
        const regex = new RegExp("[a-zA-Z]+" + protPos + "[a-zA-Z]+", "gmi");
        let growth = self.mutationsGrowth.filter((d) => {
          return (d.Mutations.match(regex) ||
            d.Mutations.startsWith("" + protPos + ":")) && d.Total > 4;
        });
        growth.forEach((row) => {
          min = min < row.recentGrowth ? min : row.recentGrowth;
          max = max > row.recentGrowth ? max : row.recentGrowth;
        });
      });

      this.updateGrowthColorScale(min, max);
      this.updateChart();
      this.hoverMostMutated(topPos);
      this.mutationsUpdated = true;
    },
    togglePopup(elementid) {
      this.hoveredSectionName = elementid;

      if (!elementid) {
        return;
      }

      const refEl = window.jQuery(`#${this.hoveredSectionName}`);
      const helpEl = window.jQuery("#helpModal");
      if (!refEl || !helpEl) {
        this.hoveredSectionName === null;
        return;
      }

      const w = helpEl.width(); //300px

      const p = refEl.offset();
      const rw = refEl.width();
      const rh = refEl.height();

      const left = p.left + rw / 2 - w / 2;
      const top = p.top + 10 + rh;

      helpEl.offset({ top: top, left: left });
    },
    isValidGroupName(group) {
      //not every key in mutation object is valid for grouping
      const valid = ["proteinAminoAcids", "aaMutationCount"];
      return valid.includes(group);
    },
    stringToId(s) {
      return "r" + s.trim().replaceAll(">", "__").replaceAll("*", "__star__");
    },
    idToString(s) {
      if (!s) return "";
      return s.replaceAll("__star__", "*").replaceAll("__", ">").substr(1);
    },
    mutationToV1(mutIn) {
      // convert mutation string from A123B to 123:A>B
      const groups = mutIn.match(this.mutV2Regex);
      if (!groups || groups.length === 0)
        return mutIn;
      else return `${groups[2]}:${groups[1]}>${groups[3]}`;
    },
    colorForAA(aa) {
      // given mutation in format 123:A>B return color based on AA grouping
      if (!aa || aa.length === 0) return "#FFFFFF";
      const p = this.mutationToV1(aa).split(":");
      if (p.length < 2) return "#FFFFFF";
      return this.colorScale(this.stringToId(p[1]));
    },
    colorForAAKey(changeKey) {
      // given mutation in format rA__B return color based on AA grouping
      if (!changeKey || changeKey.length === 0) return "#FFFFFF";
      return this.colorScale(changeKey);
    },
    colorForAAEnergy(aa) {
      // given mutation in format PDBPOS123:A>B return color based on energy calculations
      if (!this.energyAvailable || !this.energyColorScale) return "#FFFFFF";
      if (!aa || aa.length === 0) return "#BBBBBB";

      const me = this.mutationEnergy.pdb[this.mutationToV1(aa)];
      return me ? this.energyColorScale(me.energy) : "#BBBBBB";
    },
    colorForAAEnergyC(aa) {
      // given mutation in format ProtPOS123:A>B return color based on energy calculations
      if (!this.energyAvailable || !this.energyColorScale) return "#FFFFFF";
      if (!aa || aa.length === 0) return "#BBBBBB";

      const me = this.mutationEnergy.prot[this.mutationToV1(aa)];
      return me ? this.energyColorScale(me.energy) : "#BBBBBB";
    },
    colorForGrowth(val) {
      if (!this.mutationsGrowth || !this.growthColorScale || isNaN(val))
        return "##BBBBBB";
      if (val === 0) return "#CCCCCC";
      return this.growthColorScale(val);
    },
    colorForBlosum(aa) {
      // given mutation in format 123:A>B return color based on blosum substitution
      if (!aa || aa.length === 0) return "#BBBBBB";
      if (!this.blosumMatrix || !this.blosumColorScale) return "#BBBBBB";
      return this.blosumColorScale(this.getBlosumScore(this.mutationToV1(aa)));
    },
    getTextColor(bgcolor) {
      let rgb = window.d3.rgb(bgcolor);
      //https://gomakethings.com/dynamically-changing-the-text-color-based-on-background-color-contrast-with-vanilla-js/
      // Get YIQ ratio
      let yiq = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;

      // Check contrast
      return yiq >= 128 ? "#000" : "#FFF";
    },
    getRecentGrowthForAA(aa) {
      const self = this;
      const aa1 = self.mutationToV1(aa)
      const m = this.mutationsGrowth.filter((a) => self.mutationToV1(a.Mutations) === aa1);

      return m.length ? m[0].recentGrowth : "N/A";
    },
    getBlosumScore(aa) {
      if (!this.blosumMatrix) return 0;
      // given mutation in format 123:A>B return blosum score
      const p = this.mutationToV1(aa).split(":");
      if (p.length < 2) return 0;
      const change = p[1].split(">"); // now we have A and B
      const A = this.blosumMatrix[change[0]];
      if (change.length < 2 || !A) return 0;
      const B = A[change[1]];
      return B ? B : 0;
    },
    updateGrowthColorScale(min, max) {
      this.growthColorScale = window.d3
        .scaleLinear()
        .domain([min, (max - min) / 2, Math.ceil(max)])
        .range(this.growthColors);
    },
    mergeMutations() {
      const data = this.mutations;
      if (!data || !data.length) return;

      let mutationsMergedPerCodon = []; // result
      let seen = []; // list of processed genomic positions

      const self = this;

      const mergeCodonMutation = function (startVariant, structPos, mlist) {
        // sort codon bvy genomic position
        mlist = mlist.sort((a, b) => {
          return a.variant - b.variant;
        });

        let res = {
          // total number of mutations, not needed? use codonAaChanges length instead?
          mutationNumber: 0,
          aaMutationCount: 0,
          codon: mlist, // unmerged list
          structPos: structPos,
          variant: startVariant,
          // all AA changed occured in this codon, [position:change, count, [positions]]
          codonAaChanges: [],
        };

        let mergedAAChanges = {}; //all mutations from this codon

        // regex to test to acceptable mutations, should be onle single letter changes
        // const acceptableChangesRegex =
        //   /^([ARNDCQEGHILKMFPSTWYV]){1}>([ARNDCQEGHILKMFPSTWYV]){1}$/gi;

        mlist.forEach((m) => {
          let aaChanges = {}; // all mutations from this genomic position; sometimes have duplicages as well

          // list of individual mutation decriptions,
          //each in the format: PDBPosition:A>B:count
          const parts = m.proteinAminoAcids.trim().split(";");

          parts.forEach((p) => {
            if (p.trim().length > 0) {
              const vals = p.trim().split(":");
              if (vals.length === 3) {//&& vals[1].match(acceptableChangesRegex)) {
                // key = PDBPosition:A>B
                const akey = vals[0] + ":" + vals[1];
                // how many time we've seen this change?
                let seenChangeCount = aaChanges[akey] ? aaChanges[akey] : 0;
                aaChanges[akey] = seenChangeCount + parseInt(vals[2]);

                // same for merged
                let seenMChangeCount = mergedAAChanges[akey]
                  ? mergedAAChanges[akey]
                  : 0;
                mergedAAChanges[akey] = seenMChangeCount + parseInt(vals[2]);
              }
            }
          });
        });

        const aaUnfiltered = Object.entries(mergedAAChanges);
        // only keep changes which occured more that CUTOFF=5 times
        const filteredByCutoff = aaUnfiltered.filter((r) => r[1] >= 5);

        res.codonAaChanges = filteredByCutoff.sort((a, b) => a[1] - b[1]);

        res.aaMutationCount = res.codonAaChanges.length;
        let sum = 0;
        filteredByCutoff.forEach((v) => {
          sum += v[1];
        });
        res.mutationNumber = sum;
        return res;
      };

      data.forEach((m) => {
        // position in PDB
        const structPos = self.dna2struct(m.variant);

        // convert genomic to protein and back to get the start  of the  codon:
        const x = self.prot2dna(
          structPos,
          self.structure.start,
          self.structure.end,
          self.structure.properties.alignment_start
        );

        if (!seen.includes(x)) {
          // haven't seen this position yet, have to merge mutations from this codon into single list
          const codon = [x, x + 1, x + 2];

          const codonMutations = mergeCodonMutation(
            x,
            structPos,
            data.filter((q) => codon.includes(q.variant))
          );
          seen.push(x, x + 1, x + 2);
          mutationsMergedPerCodon.push(codonMutations);
        }
      });

      return mutationsMergedPerCodon;
    },
    groupData(data, group) {
      // group mutations based ot the parameter provided
      //returns array of {variant:1, values:{name1:value1, name2:value2}}
      if (!this.isValidGroupName(group)) {
        this.log("Not a valid group name");
        return;
      }

      const self = this;
      let res = [];
      let min = 0,
        max = 0;
      const cutoff = group === "aaMutationCount" ? 1 : 5; // min count of changes to display

      let subgroups = []; // list of all possible names in grouped data values

      const updateMinMax = function (v, i) {
        if (i === 0) {
          min = v;
          max = v;
        } else {
          min = Math.min(min, v);
          max = Math.max(max, v);
        }
      };

      const toExclude = ["fs"]; // list of aa change types to exclude from counts

      data.forEach((m, i) => {
        /*
          aaMutationCount: 2
          alt: "AG>GA:14; A>G:61782; " - do not use
          annotationType: "SNP; " - do no use
          mutationNumber: 61796
          mutationType: "missense_variant; "  - do not use
          proteinAminoAcids: "90:K>R; 90:K>R; " - do not use
          refRegion: "nsp5"  - do not use
          variant: 10323  - do not use
        */

        let row = {
          variant: m.variant,
          pdbPos: m.structPos, //self.dna2struct(m.variant),
          m: m,
          count: 0,
        };

        if (group === "proteinAminoAcids" || group === "aaMutationCount") {
          // use codonAaChanges[]
          //    codonAaChanges: [['165:M>I', 87], ['165:M>L', 15]...]
          m.codonAaChanges &&
            m.codonAaChanges.forEach((vals) => {
              if (vals.length > 1) {
                // make sure it's a valid array with 2+ elements
                const aacount = +vals[1]; // count of changes
                const posChange = vals[0].split(":"); // [position, change]
                // for groupit we don't need position, only actual aa change

                if (posChange.length > 1 && !toExclude.includes(posChange[1])) {
                  // aa change converted to valid html id
                  const k = self.stringToId(posChange[1]);
                  subgroups.push(k);

                  // if thes key has been seen before - sum them up
                  let prevV = row[k] ? row[k] : 0;
                  const v = prevV + aacount;

                  if (group === "proteinAminoAcids") {
                    row[k] = v;
                    row.count += v;
                  } else if (group === "aaMutationCount") {
                    row[k] = prevV + 1;
                    row.count = +m[group];
                  }
                  updateMinMax(row.count, i);
                }
              }
            });
        }

        if (row.count >= cutoff) res.push(row);
      });

      const compare = function (a, b) {
        return parseInt(a["pdbPos"]) - parseInt(b["pdbPos"]);
      };

      return {
        data: res.sort(compare),
        min: min,
        max: max,
        subgroups: [...new Set(subgroups)], //get rid of dublicated groups
      };
    },
    updateChart() {
      // based on https://www.d3-graph-gallery.com/graph/barplot_stacked_highlight.html
      const d3 = window.d3;
      if (!d3) {
        this.log("D3 not found");
        return;
      }

      if (this.mergedMutations.length === 0) return;

      if (!this.colorSet)
        this.colorSet = [
          "#1f77b4",
          "#ff7f0e",
          "#2ca02c",
          "#d62728",
          "#9467bd",
          "#8c564b",
          "#e377c2",
          "#7f7f7f",
          "#bcbd22",
          "#17becf",
          "#1b9e77",
          "#d95f02",
          "#7570b3",
          "#e7298a",
          "#66a61e",
          "#e6ab02",
          "#a6761d",
          "#666666",
          "#8dd3c7",
          "#bebada",
          "#fb8072",
          "#80b1d3",
          "#fdb462",
          "#b3de69",
          "#fccde5",
          "#bc80bd",
          "#ccebc5",
          "#66c2a5",
          "#fc8d62",
          "#8da0cb",
          "#e78ac3",
          "#a6d854",
          "#ffd92f",
          "#e5c494",
          "#b3b3b3",
          "#4e79a7",
          "#f28e2c",
          "#e15759",
          "#76b7b2",
          "#59a14f",
          "#edc949",
          "#af7aa1",
          "#ff9da7",
          "#9c755f",
          "#bab0ab",
          "#a6cee3",
          "#1f78b4",
          "#b2df8a",
          "#33a02c",
          "#fb9a99",
          "#e31a1c",
          "#fdbf6f",
          "#ff7f00",
          "#cab2d6",
          "#6a3d9a",
          "#b15928",
          "#7fc97f",
          "#beaed4",
          "#fdc086",
          "#386cb0",
          "#f0027f",
          "#bf5b17",
        ];


      // clear canvas
      d3.select("#varChartCanvas").selectAll("*").remove();

      const self = this;

      let grouped = this.groupData(this.mergedMutations, this.groupBy);
      const data = grouped.data;

      const groups = data.map((d) => d.pdbPos);

      self.subgroups = grouped.subgroups;

      // Add X axis
      const x = d3
        .scaleBand()
        .domain(groups)
        .range([0, self.width])
        .padding([0.2]);
      self.xScale = x;
      self.xAxis = d3.axisBottom(x).tickSizeOuter(0);

      self.svg
        .append("g")
        .attr("transform", `translate(0, ${self.height})`)
        .call(self.xAxis);

      // Add Y axis
      const y = d3
        .scaleLinear()
        .domain([0, grouped.max * 1.1])
        .range([self.height, 0]);
      self.yScale = y;
      self.yAxis = d3.axisLeft(y);
      self.svg.append("g").call(self.yAxis);

      // color palette = one color per subgroup
      if (!self.colorScale)
        self.colorScale = d3
          .scaleOrdinal()
          .domain(self.subgroups)
          .range(self.colorSet);

      //stack the data? --> stack per subgroup
      self.stackedData = d3.stack().keys(self.subgroups)(data);

      //clean NaNed groups
      self.stackedData.forEach((stack) => {
        for (const s of stack) {
          s.key = stack.key;
          if (Array.isArray(s)) {
            s[1] = isNaN(s[1]) ? 0 : s[1];
          }
        }
      });

      self.redrawChart();
    },
    colorForData(d) {
      if (!d) return "#BBBBBB";

      const self = this;

      const multicolored =
        self.groupBy === "proteinAminoAcids" ||
        self.groupBy === "aaMutationCount";
      if (!multicolored) {
        // all bars of the same color
        return "#1f78b4";
      }

      // mut in in PDB coordinates
      const mut = d.data.pdbPos + ":" + self.idToString(d.key);

      if (self.energyAvailable && self.colorBy === "energy") {
        return self.colorForAAEnergy(mut);
      } else if (self.mutationsGrowth && self.colorBy === "growth") {
        const mutProt = self.struct2protein(d.data.pdbPos) + ":" + self.idToString(d.key);
        return this.colorForGrowth(this.getRecentGrowthForAA(mutProt));
      } else if (self.colorBy === "blosum" && self.blosumMatrix) {
        return self.colorForBlosum(mut);
      } else {
        return self.colorForAAKey(d.key);
      }
    },
    redrawChart() {
      const d3 = window.d3;
      const self = this;
      const x = self.xScale;
      const y = self.yScale;

      self.svg
        .append("g")
        .selectAll("g")
        // Enter in the stack data = loop per AA change
        .data(self.stackedData)
        .join("g")

        .attr("class", (d) => "barStack " + d.key) // Add a class to each subgroup: their name
        .selectAll("rect")
        // enter a second time = loop subgroup per subgroup to add all rectangles
        .data((d) => d)
        .join("rect")

        .attr("fill", (d, i) => self.colorForData(d, i))
        .attr("data-val", (d) => d[1])
        .attr("x", (d) => x(d.data.pdbPos))
        .attr("y", (d) => y(d[1]))
        .attr("height", (d) => {
          let h = y(d[0]) - y(d[1]);
          return h >= 0 ? h : 0;
        })
        .attr("width", x.bandwidth())
        .attr("stroke", "grey")
        .on("mouseover", function () {
          // what subgroup are we hovering?
          // const subGroupName = d3.select(this.parentNode).datum().key;

          // Reduce opacity of all rect to 0.2
          self.svg.selectAll("rect").style("opacity", 0.2);

          // Highlight all rects of this subgroup with opacity 1. It is possible to select them since they have a specific class = their name.
          // d3.selectAll("." + subGroupName).style("opacity", 1);
          d3.select(this).style("opacity", 1);
          self.hoveredColumn = this.__data__;
        })
        .on("mousemove", function () {
          self.hoveredSubgroupName = d3.select(this.parentNode).datum().key;
          self.hoveredColumn = this.__data__;
        })
        .on("mouseleave", function () {
          d3.selectAll(".barStack rect").style("opacity", 1);
        })
        .on("click", function () {
          self.hoveredColumn = this.__data__;
          self.zoomTo(this.__data__.data.m.variant);
        });
    },
    hoverMostMutated(topPos) {
      const self = this;
      // make sure growth chart is ready before updating it
      self.hoveredColumn = self.stackedData[0].find(
        (e) => e.data.pdbPos === topPos
      );
      // need this line to force recalc of mutGrowthAtCurrentPosition
      self.log(
        `Most mutated position ${topPos}: ${self.mutGrowthAtCurrentPosition.length}`
      );
    },
    async loadEnergyData() {
      // loads  energy data for current structure
      // #Mutated Chain,Mutation,PremPLI,Interface?
      // A,H41Y,0.95,Yes
      const self = this;

      const pdb = self.structure.id;

      self.energyAvailable = false;
      // store two versions of the energy data.
      //one bases of pdb coordinates, another - on protein
      self.mutationEnergy = { pdb: {}, prot: {} };

      const reformatMutation = function (m) {
        // convert H41Y to 41:H>Y
        const rematches = /(\D+)(\d+)(\D+)/gi.exec(m);
        if (rematches.length === 4) {
          const pos = self.struct2protein(+rematches[2]);
          return [
            `${+rematches[2]}:${rematches[1]}>${rematches[3]}`,
            `${pos}:${rematches[1]}>${rematches[3]}`,
          ];
        } else return m;
      };

      self.energyRange = { min: 0, max: 0 }; // energy range
      let range = self.energyRange;

      await window.d3
        .csv(self.mainDataUrl + self.energyFolder + pdb + ".csv", (d) => {
          const formatted = reformatMutation(d.Mutation);
          const energy = +d.PremPLI;
          const mPDB = {
            mutation: formatted[0],
            energy: energy,
          };
          const mProt = {
            mutation: formatted[1],
            energy: +d.PremPLI,
            // interface:
            //   (d["Interface?"] && d["Interface?"]) === "Yes" ? true : false,
          };

          range.min = range.min > energy ? energy : range.min;
          range.max = range.max < energy ? energy : range.max;

          self.mutationEnergy.pdb[mPDB.mutation] = mPDB;
          self.mutationEnergy.prot[mProt.mutation] = mProt;

          // return me;
        })
        .catch((e) => {
          self.log("Unable to load energy data" + e);
          self.energyAvailable = false;
        });

      self.energyAvailable = true;
      self.energyRange = range;
      self.energyColorScale = window.d3
        .scaleSequential()
        .domain([range.min, range.max])
        .interpolator(window.d3.interpolatePuOr);
    },
    async loadBlosum() {
      const self = this;
      window.d3
        .json(self.mainDataUrl + "./drugresistance/blosum62.json")
        .then((res) => {
          self.blosumMatrix = res;
          // const domain =  [-4,        -3,         -2,        -1,        0,       1,         2,        3,       4,        5]
          // const scheme = ["#8e0152","#c51b7d","#de77ae","#f1b6da","#ffffff","#e6f5d0","#b8e186","#7fbc41","#4d9221","#276419"];
          self.blosumColorScale = window.d3
            .scaleSequential()
            .domain([-5, 5])
            // .range (scheme)
            .interpolator(window.d3.interpolatePiYG);
        })
        .catch((e) => {
          self.log("Unable to load blosum matrix" + e);
        });
    },
    async loadGrowthData() {
      // might be better to refactor this to use single function
      // for both LineageTracking page and DrugResistance page
      if (!this.protein || this.protein.length === 0) {
        this.log(`Unable to load growth data, no protein provided`);
        return;
      }
      //load mutations growth data for selected protein
      const fileName = `${this.mainDataUrl}${this.mutationGrowthFolder
        }World.${this.protein.toUpperCase()}_mutations_all.tsv`;

      const self = this;
      self.mutationsGrowth = [];

      await window.d3
        .text(fileName)
        .then((results) => {
          if (results.startsWith("<")) {
            // no such file, vue returns html in this case
            self.error = {
              hasError: true,
              text: "No data file found on server. Please try another set.",
            };
            return;
          }
          // eslint-disable-next-line no-console
          console.log("data loaded");
          //  let lines = results.split("\n");

          let data = window.d3.tsvParse(results);

          // need to notmalize results and calc recent growth
          let totalGenomes = [];

          // range of the _normalized_ growth
          // let globalMin = 0;
          // let globalMax = 0;
          data.forEach((row, i) => {
            for (const [k, v] of Object.entries(row)) {
              if (k != "Mutations") row[k] = +v; // convert to number
            }

            if (i === 0) {
              // data[0] contains total for all mutations per date
              totalGenomes = row;
            } else {
              let total4Lineage = row["Total"];
              // let range =
              self.normalize(row, totalGenomes, total4Lineage);
              // globalMin = globalMin > range.min ? range.min : globalMin;
              // globalMax = range.max > globalMax ? range.max : globalMax;
            }
          });
          // self.updateGrowthColorScale(globalMin, globalMax);

          self.mutationsGrowth = data;
        })
        .catch((e) => {
          self.log("Unable to load growth data" + e);
        });
    },
    normalize(row, totalGenomes, total4Lineage) {
      // this is a copy from LineageTracking
      /** LJ:
       * it basically takes each number from the green part (raw genome counts)
       * divides it by the total number of genomes in a lineage
       * (here column L; note: it is just a sum of a row)
       * and by the corresponding total number of genomes in that date (week really)
       * which is provided at the bottom of the green area
       * (here row 121 – note: it is _not_ a sum of a column)
       * and, finally, multiples the result by the total number of genomes
       * sequenced in the entire period (here $L$121).
       * */
      let result = [];
      let raw = [];
      let range = { min: 0, max: 0 };
      let normRange = { min: 0, max: 0 };

      let total = totalGenomes.Total; //[totalGenomes.length - 1];

      for (const [kk, vv] of Object.entries(row)) {
        if (Date.parse(kk)) {
          let n = (vv * total) / (total4Lineage * totalGenomes[kk]);
          isNaN(n) && (n = 0);
          let m = +n.toFixed(2);
          result.push(m);
          raw.push(vv);

          range.min = range.min < vv ? range.min : vv;
          range.max = range.max > vv ? range.max : vv;

          normRange.min = normRange.min < m ? normRange.min : m;
          normRange.max = normRange.max > m ? normRange.max : m;
        } else row[kk] = vv;
      }

      let l = result.length;
      row.recentGrowth = +(
        (result[l - 1] + result[l - 2] + result[l - 3]) /
        3
      ).toFixed(2);
      row.normalized = result;
      row.normRange = normRange;
      row.raw = raw;
      row.range = range;
      return normRange;
    },
    struct2protein: function (x) {
      if (this.structure.properties.alignment_start === 1) return x;
      // convert PDB coordinate to protein
      return Math.floor(
        (this.structure.start - this.structure.protstart) / 3 +
        (x - this.structure.properties.alignment_start + 1)
      );
    },

    dna2struct: function (x) {
      if (this.structure && this.structure.type === "pdb")
        return this.dna2prot(
          x,
          this.structure.start,
          this.structure.end,
          this.structure.properties.alignment_start
        );
      return "";
    },
    zoomTo(pos) {
      // shows selecte position on 3D view
      this.$parent.zoomTo(pos);
    },
  },
};
</script>

<style scoped>
a.sorting {
  font-weight: bolder;
  color: #007bff;
  cursor: pointer;
}

#vchartTooltip {
  position: absolute;
  background-color: white;
  border: solid;
  border-width: 2px;
  border-radius: 5px;
  padding: 5px;
}

.borderbottom {
  border-bottom: 3px solid;
}
</style>
